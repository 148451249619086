import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={data => (
      <div className="herobox">
  <     div className="hero-header">
          <div className="headline">{data.site.siteMetadata.home.title}</div>
          {/* <div 
            className="primary-content" 
            dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.home.description}}
          /> */}
          <h2 className="subheadline">{data.site.siteMetadata.home.description}</h2>
          <div className="hero-button">
            <Link to='/projects' className="btn -primary">See my projects &rarr;</Link>
          </div>
        </div>
        <div className="hero-media">
          <img src="/assets/lotus_demo.gif" alt="spinning_lotus"/>
        </div>
        
      </div>
      
    )}
  />
)