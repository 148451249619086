//import React, { useState } from "react"
import React from "react"


export default function Intro() {
  //const [flipped, setFlipped] = useState(false)

  function getText(flip) {
    if (flip) { return <div className=" intro-text">
        Please feel free to reach out via email or Twitter if you'd like to collaborate, chat, or just for fun. <br/>
        Profile links are at the bottom of the page. <br/>
      </div>
    } else { return <div className=" intro-text">
        I'm a Data Scientist / Maker / Dev based in Vienna, Austria. <br/>
        This page shows what I've been up to lately and thoughts I feel are worth sharing. <br/>
      </div>
    }
  }
  //onClick={() => setFlipped(!flipped)}
  return (
    <div>
      <div class="flip-intro" > 
        <div class="flip-intro-inner">
          <div class="flip-intro-front">
            {getText(false)}
          </div>
          <div class="flip-intro-back">
            {getText(true)}
          </div>
        </div>
      </div>

    </div>
    
  )
}