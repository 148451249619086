import React from "react"
import Helmet from 'react-helmet';
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import HeroHeader from "../components/heroHeader"
import Interests from "../components/interests";
import Intro from "../components/intro";

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .filter(edge => !(edge.node.frontmatter.path === '/about'))
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
    .slice(0,4)

  return (
    <Layout>
      <Helmet>
        <script async defer data-website-id="b0bf6ddf-416e-46db-9269-ee92ad54f593" src="https://umamiii.vercel.app/umami.js"></script>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <HeroHeader/>
      <Intro/>
      <h1 className="centered h1">Interests</h1>
      <h2 className="centered h2">(Click or hover to expand)</h2>
      <Interests/>
      <h1 className="centered h1">Recent Projects</h1>
      <h2 className="centered h2">Some of my recent interesting projects</h2>
      <div className="grids">
        {Posts}
      </div>
      <div className="centered bottom-button">
        <Link to='/projects' className="btn -primary">More projects &rarr;</Link>
      </div>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            metaDescription
          }
        }
      }
    }
  }
`
